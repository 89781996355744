<template>
  <div class="animated fadeIn container">
    <h1>请选择想充值的数量进行充值</h1>
    <div class="row button-container mt-4">
      <div class="col-md-2 col-sm-2">信息充值</div>
      <div class="col-md-4 col-sm-3">
        <!-- <input type="text" v-model="money" /> -->
        <span style="font-size: 20px">{{ money | numberFormat }}</span>
        <span class="ml-1">条</span>
      </div>
      <div class="col col-sm-6 text-center">
        <ul class="button-list">
          <li
            :key="prefixMoney"
            class="btn-primary"
            v-for="prefixMoney in prefixMoneys"
            @click.prevent="money += prefixMoney"
          >
            <span class="d-md-down-none">+</span> {{ prefixMoney | numberFormat }}
          </li>
          <li class="btn-secondary" @click.prevent="money = 0">
            <span style="color: black">重新输入</span>
          </li>
        </ul>
      </div>
    </div>

    <p>
      <a href="/payment/history" class="history-link color-flatwhite">转到充值内容页面</a>
    </p>
    <b-btn variant="primary" class="mt-4 payment-btn" @click.prevent="createPayment()">请求充值</b-btn>
    <p class="color-flatwhite" style="margin-top: 100px">* 充值后需管理员确认</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PaymentService from '@/services/PaymentService'

export default {
  name: 'paymentStep1',
  data() {
    return {
      money: 1000,
      prefixMoneys: [1, 10, 100, 1000, 10000, 100000, 1000000],
      paymentResult: null,
    }
  },
  mounted() {
    var IMP = window.IMP // 생략가능
    IMP.init('imp17932233')
    this.getUserInfo()
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    availableSendCount() {
      return parseInt(parseFloat(this.money) / this.smsFee)
    },
  },
  methods: {
    async createPayment() {
      const response = await PaymentService.create({ amount: this.money })
      if (response.status != 200) {
        return
      }

      if (!response.data.payment) {
        alert('请求充值失败')
      }

      alert('请求充值成功')
    },
  },
}
</script>

<style scoped>
.button-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.button-container > div {
  display: flex;
  align-items: center;
}

.button-list {
  list-style: none;
  width: 100%;
  line-height: 20px;
}

.button-list > li {
  float: left;
  color: white;
  position: relative;
  width: 23%;
  margin-left: 2%;
  padding: 4px 0;
  margin: 3px 1%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.5px;
  height: 28px;
  overflow: auto;
  min-width: 70px;
}

.payment-btn {
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
}

.history-link {
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}

.history-link:hover {
  color: #333;
}
</style>
